@import 'constants/styles.constants.module';

.lightFont {
  font-weight: $fontWeightLight;
}

h1.whiteFont,
h3.whiteFont {
  color: $colorWhite;

  @include max(s) {
    color: $colorMain;
  }
}

h1.smaller {
  font-size: $fontSizeTitle2;
}

h1.center {
  text-align: center;
}

.text {
  display: block;
  margin-bottom: 2.5rem;
  font-weight: $fontWeightLight;
  font-size: 1.125rem;
}
