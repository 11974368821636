@import 'constants/styles.constants.module';

div.root {
  position: absolute;
  left: 1.5rem;
  width: 13.75rem;
  margin-top: 3.5rem;
  padding-top: 5.312rem;
  padding-bottom: 1.5rem;
  background-color: var(--colorBackground);
  border-radius: 0.5rem;
  box-shadow: $boxShadowEqual;

  @include max(md) {
    width: 13rem;
  }

  @include max(sm) {
    position: relative;
    left: unset;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: unset;
    margin-top: 5rem;
    margin-bottom: 3rem;

    div > a {
      border-left: unset;
    }
  }
}

div.marginTop {
  margin-top: 3rem;
}

.cardIcon {
  right: 0.3rem;
  @include max(sm) {
    position: absolute;
    top: 0.5rem;
    right: unset;
    left: calc(50% + 4.5rem);
  }
}

.downgraded {
  background-color: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
}
