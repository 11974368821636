@import 'constants/styles.constants.module';

.environmentBar {
  padding: 0.25rem 0;
  color: #fff;
  font-weight: bold;
  text-align: center;

  &.development {
    background-color: #9d2d2d;
  }

  &.production {
    display: none;
  }

  &.test {
    background-color: #3d60d3;
  }
}
