@import 'constants/styles.constants.module';

.welcomeAlert {
  align-items: center;
  background-color: $colorYellow !important;
  border-radius: 0.5rem;
  box-shadow: $boxShadowImage;
  display: flex;
  font-size: $fontSizeBiggerText;
  font-weight: $fontWeightMedium;
  margin-bottom: 1rem;
  padding: 1rem;
  vertical-align: middle;
}

.welcomeAlert > span {
  padding-left: 1rem;
}

.icon {
  display: inline-block;
  height: 1.25rem;
  stroke: none;
}

.icon > path {
  fill: $colorMain;
}
