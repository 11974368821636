@import 'constants/styles.constants.module';

.root {
  height: 10rem;
  min-height: 55px;
  width: auto;
  @include ie-only() {
    width: 10rem;
  }

  @include max(xs) {
    height: 5rem;
  }
}

.clickable {
  &:hover,
  &:focus {
    cursor: pointer;
  }
}
