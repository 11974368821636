@import 'constants/styles.constants.module';

.inputs {
  textarea {
    height: 10rem;
    padding: 1rem;
    color: $colorMain;
    font-weight: $fontWeightLight;
    border: 1px solid #26336055;
    border-radius: 0.5rem;
  }
}
