@import 'constants/styles.constants.module';

.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.gradientRoot {
  position: absolute;
  top: 0;
  left: 0;
  width: 110%;
  height: 700px;

  @include max(xxl) {
    width: 130%;
  }

  @include max(xl) {
    width: 110%;
  }

  @include max(lg) {
    width: 110%;
  }

  @include max(md) {
    width: 105%;
  }

  @include max(sm) {
    width: 100%;
  }

  @include max(s) {
    display: none;
  }
}

.minimizeGradient {
  height: 480px;
}

.biggestGradient {
  height: 870px;
}
