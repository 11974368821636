.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5rem;

  h1 {
    text-align: center;
  }

  h2 {
    margin-top: 1rem;
    text-align: center;
  }

  .container {
    margin-top: 2rem;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  button:last-child {
    margin-left: 1rem;
  }
}
