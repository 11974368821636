/* Colors */
$colorBackground: #eaf0f7;
$colorBackgroundAlpha30: #eaf0f74d;
$colorBackgroundAlpha90: #eaf0f7e6;
$colorDisabled: #6c6e70;
$colorBorder: #d1e0f2;
$colorBorderAlpha30: rgba(209, 224, 242, 0.24);
$colorBorderLine: #626c8b47;
$colorMain: #263360;
$colorMainAlpha55: #26336055;
$colorMenuBackdrop: #263360f7;
$colorSecondary: #3d60d3;
$colorSecondaryHover: #516fd4;
$colorWarning: #d93636;
$colorDarkHighlight: #9d2d2d;
$colorLightBlue: #7ca8d5;
$colorTextSecondary: #626c8b;
$colorGray: #636d8b;
$colorBackgroundHighlightAlpha30: #bad0ea4d;
$colorSuccess: #40c435;
$colorOrange: #ffba00;
$colorOrangeDark: #b97b07;
$colorCheckboxBorder: #9da3b5;
$colorIcon: #b6bbcc;
$colorLightHover: #eaf1f8;
$colorBackgroundGreen: #d1f2de;
$colorBackgroundOrange: #ffecbf;
$colorBackgroundBlue: #88a9f0;
$colorBackgroundGray: #cecece;
$colorInputDisabled: #e7e7e7;
$colorBackgroundRed: #f2d1d1;
$colorGreen: #3be07b;
$colorGreenDark: #1f7e44;
$colorArchive: #36383e;

$colorWhite: #fff;
$colorBlack: #000;
$colorRed: #f1383d;
$colorYellow: #fede3a;

$backgroundToastBlue: #5bc0de;
$backgroundToastRed: #d9534f;
$backgroundToastGreen: #5cb85c;
$backgroundToastOrange: #f0ad4e;

/* Font Weights */
$fontWeightLight: 300;
$fontWeightNormal: 400;
$fontWeightMedium: 500;
$fontWeightSemiBold: 600;

/* Font Size */
$fontSizeTitle1: 3rem;
$fontSizeTitle2: 1.875rem;
$fontSizeTitle3: 1.5rem;
$fontSizeBiggestText: 1.437rem;
$fontSizeBiggerText: 1.25rem;
$fontSizeText: 1rem;
$fontSizeSmallText: 0.875rem;
$fontSizeSmallestText: 0.8rem;
$fontSizeButton: 1rem;

/* stylelint-disable unit-allowed-list */
/* Shadow */
$boxShadowCard: 0 60px 60px #00145829;
$boxShadowButton: 0 5px 20px #00145834;
$boxShadowImage: 10px 10px 20px #00000029;
$boxShadowContextMenu: 0 10px 30px #00145833;
$boxShadowInput: 0 15px 30px #0014580d;
$boxShadowTable: 0 30px 30px #0014580d;
$boxShadowEqual: 0 0 30px #0014580d;

$boxShadowContextMenuIE: 0 10px 30px rgba(0, 20, 88, 0.2);
$boxShadowInputIE: 0 15px 30px rgba(0, 20, 88, 0.05);

$breakpoints: (
  xxs: 500px,
  xs: 730px,
  s: 810px,
  sm: 920px,
  m: 1063px,
  md: 1290px,
  lg: 1620px,
  xl: 1920px,
  xxl: 2400px,
  xxxl: 3180px,
);
/* stylelint-enable unit-allowed-list */

@mixin max($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin ie-only() {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin back-drop($backdropColor) {
  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    background-color: rgba($backdropColor, 0.8);
    backdrop-filter: blur(0.687rem);
    backdrop-filter: blur(0.687rem);
  }
}
