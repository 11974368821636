@import 'constants/styles.constants.module';

%base-link {
  position: relative;
  width: 100%;
  padding-left: 2.937rem;
  color: $colorMain;
  line-height: 2rem;
  text-decoration: none;
  border-left: 0.187rem solid var(--colorBackground);

  &:hover,
  &:focus,
  &.active {
    color: var(--colorSecondaryHover);
    border-left-color: var(--colorSecondaryHover);

    .icon {
      stroke: var(--colorSecondaryHover);
    }
  }
}

.link {
  @extend %base-link;
  font-weight: 200;
  font-size: $fontSizeText;

  &:hover,
  &:focus,
  &.active {
    font-weight: $fontWeightNormal;
  }
}

.linkMain {
  @extend %base-link;
  font-weight: 600;
  font-size: $fontSizeBiggerText;
  text-transform: uppercase;
}

.noSubLinks {
  margin-top: 0.5rem;
}

.noHover {
  &:hover,
  &:focus,
  &.active {
    color: $colorMain !important;
    border-left-color: transparent !important;

    .icon {
      stroke: $colorMain !important;
    }
  }
}

.linksBlock {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include max(sm) {
    width: 33%;
  }

  @include max(xxs) {
    width: 50%;
  }
}

svg.icon {
  position: absolute;
  top: calc(50% - 0.125rem);
  left: 2rem;
  height: 0.25rem;
  stroke: $colorMain;
}
