@import 'constants/styles.constants.module';

%boxcolorshard {
  label {
    color: $colorGray;
  }

  div {
    color: $colorMain;
  }
}

.reset {
  position: relative;
  top: auto;
  right: 1rem;
  left: auto;
}

.spaceBetween {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.container {
  flex-basis: 100%;
  max-width: 100%;

  &.S30 {
    flex-basis: calc(30% - 1rem);
    max-width: calc(30% - 1rem);

    @include max(xs) {
      width: 100%;
    }
  }

  &.S40 {
    flex-basis: calc(40% - 1rem);
    max-width: calc(40% - 1rem);

    @include max(xs) {
      width: 100%;
    }
  }

  &.S50 {
    flex-basis: calc(50% - 1rem);
    max-width: calc(50% - 1rem);

    @include max(xs) {
      width: 100%;
    }
  }

  &.S60 {
    flex-basis: calc(60% - 1rem);
    max-width: calc(60% - 1rem);

    @include max(xs) {
      width: 100%;
    }
  }

  &.S70 {
    flex-basis: calc(70% - 1rem);
    max-width: calc(70% - 1rem);

    @include max(xs) {
      width: 100%;
    }
  }

  &.S80 {
    flex-basis: calc(80% - 1rem);
    max-width: calc(80% - 1rem);

    @include max(xs) {
      width: 100%;
    }
  }
}

.root {
  align-items: stretch !important;
  width: 100%;

  &.marginTop {
    margin-top: 2rem;
  }

  a {
    color: var(--colorSecondary);
    text-decoration: underline;

    &:hover {
      color: var(--colorLinkHover);
    }
  }

  .block {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    &.toRight {
      justify-content: flex-end;
    }
  }

  .wrapper {
    @include back-drop($colorMain);
    display: flex;
    flex-flow: wrap;
    margin-top: 1rem;
    padding: 1.3rem 2rem;
    background-color: $colorMain;
    border-radius: 0.5rem;
    box-shadow: $boxShadowTable;

    a {
      color: $colorWhite;
      /* stylelint-disable max-nesting-depth */
      &:hover {
        color: var(--colorLinkHover);
      }
    }
  }

  .bcgGreen {
    @include back-drop($colorGreenDark);
    background-color: $colorGreenDark;

    div {
      color: $colorWhite;
    }

    label {
      color: $colorWhite;
    }
  }

  .bcgDarkOrange {
    @include back-drop($colorOrangeDark);
    background-color: $colorOrangeDark;
  }

  .bcgDarkHighlight {
    @include back-drop($colorDarkHighlight);
    background-color: $colorDarkHighlight;
  }

  .bcgWhite {
    @include back-drop($colorWhite);
    background-color: $colorWhite;

    div {
      color: $colorMain;
    }

    label {
      color: $colorGray;
    }

    a {
      color: var(--colorSecondary);
      text-decoration: none;

      &:hover {
        color: var(--colorLinkHover);
      }
    }
  }

  .bcgRed {
    background-color: $colorRed;
  }

  .bcgDarkRed {
    background-color: #582e32;
  }

  .archivedBcg {
    @include back-drop($colorArchive);
    background-color: $colorArchive !important;

    a {
      color: $colorWhite;
      text-decoration: underline;
    }
  }
}

.warningWrapper {
  @include back-drop(rgb(240, 173, 78));
  height: 100%;
  margin-top: 1rem;
  padding: 1.3rem 2rem;
  background-color: rgb(240, 173, 78);
  border-radius: 0.5rem;
  box-shadow: $boxShadowTable;

  .noWarnings {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    color: $colorWhite;
  }

  h2.bold {
    font-weight: $fontWeightMedium;
  }

  &.successBlock {
    @include back-drop(rgb(31, 126, 68));
    background-color: rgba(31, 126, 68, 0.8);
    backdrop-filter: blur(0.687rem);
  }

  h2.bold {
    font-weight: $fontWeightMedium;
  }

  .content {
    ul {
      margin-left: -1rem;
    }
  }
}
