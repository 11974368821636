@import 'constants/styles.constants.module';

.profileImage {
  position: absolute;
  top: -6.375rem;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 10.125rem;
  height: 10.125rem;
  text-align: center;
  background-color: $colorBackground;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 15rem;
  box-shadow: $boxShadowImage;
  transform: translateX(-50%);

  @include max(sm) {
    top: -3.5rem;
    width: 8rem;
    height: 8rem;
  }
}

.text {
  line-height: 2rem;
  text-transform: uppercase;

  div {
    font-weight: $fontWeightMedium;
  }

  div:first-child {
    font-size: $fontSizeTitle1;
  }

  div:last-child {
    font-size: $fontSizeBiggerText;
  }
}

.logo {
  background-size: 75% 75%;
}

.downgraded {
  background-color: rgba(255, 255, 255, 1) !important;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
}
