@import 'constants/styles.constants.module';

.root {
  margin-top: 1rem;
  margin-right: 1rem;
  padding: 0.125rem 1.25rem;
  color: $colorMain;
  font-size: $fontSizeSmallText;
  text-transform: uppercase;
  border-radius: 1rem;

  a {
    color: $colorMain !important;
    text-decoration: none !important;
  }
}

.root:last-child {
  margin-right: 0;
}

.lightText {
  color: $colorWhite;
}
