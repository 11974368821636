@import 'constants/styles.constants.module';

.userMenu {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  cursor: pointer;
}

.disciplineMenu {
  margin-left: 1rem;
}

.langMenu {
  margin-left: 1rem;

  .langText {
    margin: 0 8px;
    font-weight: $fontWeightLight;
  }

  svg {
    width: 25px;
  }
}

.userName {
  margin-right: 0.5rem;
  font-weight: $fontWeightLight;
}

.disciplineName {
  margin-right: 0.5rem;
  color: var(--colorSecondary);
  font-weight: $fontWeightMedium;
  font-size: $fontSizeBiggestText;
}

.icon {
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  margin-top: -0.2rem;
  margin-right: 1rem;
  border-color: $colorMain;
  border-right: 1px solid;
  border-bottom: 1px solid;
  transform: rotate(45deg);
}

.badge {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.mt3 {
  margin-top: 3rem;
}
