@import 'constants/styles.constants.module';

.root {
  padding-bottom: 0.6rem;
  padding-right: 0.312rem;
  padding-top: 0.6rem;

  a {
    color: var(--colorSecondary);
    text-decoration: none;

    &:hover {
      color: var(--colorLinkHover);
    }
  }
}

.rootBool {
  flex-basis: 10%;
  min-height: 43px;
  text-align: center;
}

.size-UNSET {
  flex-basis: unset;
}

.size-P5 {
  @extend %sizebase;
  flex-basis: 5%;
  max-width: 5%;
  padding-right: 0;
}

.size-P7 {
  @extend %sizebase;
  flex-basis: 7%;
  max-width: 7%;
  padding-right: 0;
}

.size-P10 {
  @extend %sizebase;
  flex-basis: 10%;
  max-width: 10%;
  padding-right: 0;
}

.size-P10-mw {
  @extend %sizebase;
  flex-basis: 10%;
  max-width: 10%;
  padding-right: 0;
}

/* make narrow columns wider on a mobile screen */
@media only screen and (max-width:640px) {
  .size-P10-mw {
    min-width: 100px;
  }
}

.size-P14 {
  @extend %sizebase;
  flex-basis: 14.28%;
  max-width: 14.28%;
  padding-right: 0;
}

.size-P15 {
  @extend %sizebase;
  flex-basis: 15%;
  max-width: 15%;
}

.size-P20 {
  @extend %sizebase;
  flex-basis: 20%;
  max-width: 20%;
}

.size-P25 {
  @extend %sizebase;
  flex-basis: 25%;
  max-width: 25%;
}

.size-P30 {
  @extend %sizebase;
  flex-basis: 30%;
  max-width: 30%;
}

.size-P35 {
  @extend %sizebase;
  flex-basis: 35%;
  max-width: 35%;
}

.size-P33 {
  @extend %sizebase;
  flex-basis: 33.33%;
  max-width: 33.33%;
}

.size-P40 {
  @extend %sizebase;
  flex-basis: 40%;
  max-width: 40%;
}

.size-P50 {
  @extend %sizebase;
  flex-basis: 50%;
  max-width: 50%;
}

.size-P60 {
  @extend %sizebase;
  flex-basis: 60%;
  max-width: 60%;
}

.size-P70 {
  @extend %sizebase;
  flex-basis: 70%;
  max-width: 70%;
}

.size-P100 {
  flex-basis: 100%;
  max-width: 100%;
}

%sizebase {
  @include max(m) {
    flex-basis: 50% !important;
    padding-right: 0.8rem !important;
  }
  @include max(xxs) {
    flex-basis: 100% !important;
  }
}

.valueSize-M- {
  font-size: $fontSizeText !important;
  font-weight: $fontWeightLight !important;
}

.valueSize-M {
  font-size: $fontSizeText !important;
}

.valueSize-L {
  font-size: $fontSizeBiggestText !important;

  @include max(s) {
    font-size: $fontSizeText !important;
  }
}

.label {
  color: $colorLightBlue;
  display: inline-block;
  font-size: $fontSizeSmallText;
  font-weight: $fontWeightSemiBold;
  line-height: 1.1rem;
}

.block {
  margin-bottom: 2rem;
}

.value {
  color: $colorWhite;
  font-size: $fontSizeBiggestText;
  font-weight: $fontWeightMedium;
  line-height: 1.5rem;
  overflow-wrap: break-word;
}

svg.icon {
  height: 1rem;
  width: 1rem;
}

.link {
  &:hover {
    color: var(--colorLinkHover);
  }
}

div.color-GREEN div {
  color: $colorSuccess !important;
}

div.color-BLUE div {
  color: $colorSecondary !important;
}

div.color-ORANGE div {
  color: $colorOrange !important;
}

div.color-GRAY div {
  color: $colorGray !important;
}

div.color-RED div {
  color: $colorRed !important;
}

div.color-ORANGE_SECOND div {
  > :nth-child(2) {
    color: $colorOrange !important;
  }
}

div.color-RED_SECOND div {
  > :nth-child(2) {
    color: $colorRed !important;
  }
}

div.color-GREEN_SECOND div {
  > :nth-child(2) {
    color: $colorGreen !important;
  }
}

div.color-RED_BLUE div {
  > :first-child {
    color: $colorRed !important;
  }

  > :nth-child(2) {
    color: $colorSecondary !important;
  }
}

.center {
  text-align: center;
}

.borderBottomLine {
  border-bottom: 1px solid $colorBorderLine;
}

.wrapperLabel {
  align-items: center;
  display: flex;
  justify-items: center;

  .labelTooltip {
    height: 1rem;
    margin-right: 5px;
    width: 1rem;
  }
}

.tooltipIcon {
  color: $colorLightBlue;
  cursor: help;
  fill: $colorBlack;
  height: 1.1rem;
  margin-right: 0.2rem;
  stroke: $colorBlack;
  width: 1.1rem;
}

.booleanCenter {
  justify-content: center;
}
