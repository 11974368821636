@import 'constants/styles.constants.module';

.crumb {
  color: var(--colorMain);
  font-weight: $fontWeightSemiBold;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: var(--colorLinkHover);
  }
}

.next {
  margin: 0 0.5rem;
}

.white {
  color: $colorWhite;

  @include max(s) {
    color: var(--colorMain);
  }
}
